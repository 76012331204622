import ReactECharts from 'echarts-for-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../../ui/button/button';
import { Modal } from '../../../../ui/modal/modal';
import { getJobDescription, twoDJobApplicants } from '../../../../utils/api_service/JobOpenings';
import { Applicant, ApplicantResponse, JobApplicantCSV, JobApplicantSearchRequest, PieItem, SegmentType, TabData } from '../../../../utils/types/JobOpenings';
import { SegmentModal } from '../ModalContent/segmentModal';
import { Bar3D } from '../ui/Charts/Bar3D';
import { BarChart } from '../ui/Charts/BarChart';
import { FilterWords } from '../ui/Charts/FilterWords';
import { NumberInfo } from '../ui/Charts/NumberInfo';
import { PieChart } from '../ui/Charts/PieChart';
import ExportCsv from '../ui/ExportCsv';
import { AnalysisTab } from './AnalysisSideMenu/AnalysisTab';
import { Analytic3D } from './AnalysisSideMenu/Analytic3D';
import { AnalyticAction } from './AnalysisSideMenu/AnalyticAction';
import { AnalyticFilter } from './AnalysisSideMenu/AnalyticFilter';
import { AnalyticThreshold } from './AnalysisSideMenu/AnalyticThreshold';
import styles from './Modal.module.css';

type TwoDChartProps = {
    tabTitles: string[],
    setTabTitles: (titles: string[]) => void | undefined;
    setActiveTab: (value: number) => void,
    selectedCandidates: TabData[],
    setSelectedCandidates: (tabDataObject: TabData[]) => void | undefined,
    setStatus: (status: string[]) => void | null;
    status: string[],
    statusValue: string[],
    setSimilarKeyword: React.Dispatch<React.SetStateAction<string>>;
    similarKeyword: string,
    defaultSkills: string[],
    skillsInput: string,
    jobTitleInput: string,
}

export const TwoDChart = ({
  tabTitles,
  setTabTitles,
  setActiveTab,
  selectedCandidates,
  setSelectedCandidates,
  setStatus,
  status,
  statusValue,
  setSimilarKeyword,
  similarKeyword,
  defaultSkills,
  skillsInput,
  jobTitleInput,
}: TwoDChartProps) => {

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [skillValue, setSkillValue] = useState<number[]>([0,10]);
  const [minMaxAge, setMinMaxAge] = useState<number[]>([0, 100]);
  const [view3D, setView3D] = useState<boolean>(false);
  const [scatter, setScatter] = useState<boolean>(false);
  const [markLines, setMarkLines] = useState<boolean>(true);
  const [nineBox, setNineBox] = useState<boolean>(true);
  const [experienceValue, setExperienceValue] = useState<number[]>([0,10]);
  const [segmentTitle, setSegmentTitle] = useState<string>();
  const [segmentedCandidates, setSegmentedCandidates] = useState<Applicant[]>([]);
  const [xCoords, setXCoords] = useState<number[]>([]);
  const [yCoords, setYCoords] = useState<number[]>([]);
  const [svgActiveTab, setSvgActiveTab] = useState<number>(-1);
  const [expandedChart, setExpandedChart] = useState<string | null>(null);
  const [candidates, setCandidates] = useState<Applicant[]>([]);
  const [actionSelected, setActionSelected] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();

  const defaultSegment: SegmentType[][] = [
    [
      {
        id: 1,
        name: 'Top Talent',
        xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])),
      },
      {
        xAxis: skillValue[1],
        yAxis: experienceValue[1],
      },
    ],
    [
      {
        id: 2,
        name: 'Distinguished Performer',
        xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])),
      },
      {
        xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[1],
      },
    ],
    [
      {
        id: 3,
        name: 'Specialist',
        xAxis: skillValue[0],
        yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])),
      },
      {
        xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[1],
      },
    ],
    [
      {
        id: 4,
        name: 'Rising Star',
        xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])),
      },
      {
        xAxis: skillValue[1],
        yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])),
      },
    ],
    [
      {
        id: 5,
        name: 'Solid Applicant',
        xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])),
      },
      {
        xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])),
      },
    ],
    [
      {
        id: 6,
        name: 'Developing Specialist',
        xAxis: skillValue[0],
        yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])),
      },
      {
        xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])),
      },
    ],
    [
      {
        id: 7,
        name: 'Potential Star',
        xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0],
      },
      {
        xAxis: skillValue[1],
        yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])),
      },
    ],
    [
      {
        id: 8,
        name: 'Emerging Talent',
        xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0],
      },
      {
        xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])),
      },
    ],
    [
      {
        id: 9,
        name: 'Novice',
        xAxis: skillValue[0],
        yAxis: experienceValue[0],
      },
      {
        xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])),
        yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])),
      },
    ],
  ];

  const [segmentedData, setSegmentedData] = useState<SegmentType[][]>(defaultSegment);

  const [searchObject, setSearchObject] = useState<JobApplicantSearchRequest>({
    additionalSkills: defaultSkills,
    jobTitle: [jobTitleInput],
    keywords: [],
    gender: [],
    age: {
      minAge: 0,
      maxAge: minMaxAge[1],
      includeUnknown: true,
    },
    applicantStatus: statusValue,
  });

  const [searchCSVObject, setSearchCSVObject] = useState<JobApplicantCSV>({
    additionalSkills: defaultSkills,
    jobTitle: [jobTitleInput],
    keywords: [],
    gender: [],
    age: {
      minAge: 0,
      maxAge: minMaxAge[1],
      includeUnknown: true,
    },
    applicantStatus: statusValue,
    skills: {
      min: 0,
      max: 10,
    },
    experience: {
      min: 0,
      max: 10,
    },
  });

  const fetchJobApplicants = async () => {
    try {
      const response = await twoDJobApplicants(id, searchObject);
      const responseData = await response.json();
      const getInitials = (name: string) => {
        const nameParts = name.split(' ').filter(Boolean);
        const initials = nameParts.map(part => part[0].toUpperCase()).join('');
        return initials[0] + initials[1];
      };
      const transformApplicant = (item: ApplicantResponse): Applicant => {
        return {
          userId: item.id,
          value: [item.skillCount, item.experience, item.keywordCount],
          firstName: item.firstName !== null ? item.firstName : 'undefined',
          lastName: item.lastName !== null ? item.lastName : 'undefined',
          age: item.age,
          gender: item.gender,
          name: item.firstName && item.lastName !== null ? item.firstName + ' ' + item.lastName : 'undefined',
          skills: item.skills,
          symbol: item.base64Encoded ? `image://${item.base64Encoded}` : 'circle',
          label: {
            show: item.base64Encoded ? false : true,
            formatter: getInitials(item.firstName && item.lastName !== null ? item.firstName + ' ' + item.lastName : 'undefined'),
            color: 'yellow',
            fontWeight: 'bold',
          },
        };
      };
      const highestExperienceCount = responseData.data.reduce((max:any, applicant:any) => {
        return Math.max(max, applicant.experience);
      }, 0);

      const highestSkillCount = responseData.data.reduce((max:any, applicant:any) => {
        return Math.max(max, applicant.skillCount);
      }, 0);

      const newExperienceCount = highestExperienceCount > 0 ? highestExperienceCount : 10;
      const newSkillCount = highestSkillCount > 0 ? highestSkillCount : 10;
      setExperienceValue([experienceValue[0], newExperienceCount]);
      setSkillValue([skillValue[0], newSkillCount]);

      const transformedData: Applicant[] = responseData.data.map(transformApplicant);
      setCandidates(transformedData);
    } catch (error) {
      toast.error('Error Loading Job Applicants', { position: 'bottom-center' });
    }
  };

  useEffect(() => {
    fetchJobApplicants();
  }, [searchObject]);

  useEffect(() => {
    setSearchObject({
      ...searchObject,
      applicantStatus: statusValue,
    });

    setSearchCSVObject({
      ...searchCSVObject,
      applicantStatus: statusValue,
    });
  }, [status]);

  useEffect(() => {
    setSearchCSVObject({
      ...searchCSVObject,
      skills: {
        ...searchCSVObject.skills,
        min: skillValue[0],
        max: skillValue[1],
      },
      experience: {
        ...searchCSVObject.experience,
        min: experienceValue[0],
        max: experienceValue[1],
      },
    });
    setSegmentedData(defaultSegment);
  }, [experienceValue, skillValue]);

  const handleApplyChange = () => {
    setSearchObject({
      ...searchObject,
      age: {
        ...searchObject.age,
        minAge: minMaxAge[0],
        maxAge: minMaxAge[1],
      },
    });

    setSearchCSVObject({
      ...searchCSVObject,
      age: {
        ...searchCSVObject.age,
        minAge: minMaxAge[0],
        maxAge: minMaxAge[1],
      },
    });

  };

  const handleChartClick = (chartName: string) => {
    setExpandedChart(prevChart => (prevChart === chartName ? null : chartName));
  };

  const generateAgePieData = (applicants: Applicant[]): PieItem[] => {
    const ageGroups = {
      'Unknown': 0,
      '18-25': 0,
      '25-35': 0,
      '35-50': 0,
      '50-60': 0,
      '60+': 0,
    };

    applicants.forEach((applicant) => {
      if (applicant.age === null) {
        ageGroups['Unknown']++;
      } else if (applicant.age >= 18 && applicant.age <= 25) {
        ageGroups['18-25']++;
      } else if (applicant.age > 25 && applicant.age <= 35) {
        ageGroups['25-35']++;
      } else if (applicant.age > 35 && applicant.age <= 50) {
        ageGroups['35-50']++;
      } else if (applicant.age > 50 && applicant.age <= 60) {
        ageGroups['50-60']++;
      } else if (applicant.age > 60) {
        ageGroups['60+']++;
      }
    });

    return Object.entries(ageGroups).map(([name, value]) => ({ name, value }));
  };

  const genderPieData = (applicants: Applicant[]): PieItem[] => {
    const ageGroups = {
      'Other': 0,
      'Male': 0,
      'Female': 0,
      'Non-binary': 0,
    };

    applicants.forEach((applicant) => {
      if (applicant.gender === 'Other') {
        ageGroups['Other']++;
      } else if (applicant.gender === 'Male') {
        ageGroups['Male']++;
      } else if (applicant.gender === 'Female') {
        ageGroups['Female']++;
      } else if (applicant.gender === 'Non-binary') {
        ageGroups['Non-binary']++;
      }
    });

    return Object.entries(ageGroups).map(([name, value]) => ({ name, value }));
  };

  const loadModalContent = () => {
    return (
      <SegmentModal
          segmentedCandidates={segmentedCandidates}
          xCoords={xCoords}
          yCoords={yCoords}
          setIsModalOpen={setIsModalOpen}
          selectedCandidates={selectedCandidates}
          setSelectedCandidates={setSelectedCandidates}
          setActiveTab={setActiveTab}
          setTabTitles={setTabTitles}
          tabTitles={tabTitles}
      />
    );
  };

  const renderExpandedChart = () => {
    if (expandedChart === 'Gender') {
      return (
        <PieChart
            name={expandedChart}
            pieData= {genderPieData(candidates)}
            onClick={() => handleChartClick('Gender')}
            expand={true}
        />
      );
    } else if (expandedChart === 'Age') {
      return (
        <PieChart
            name={expandedChart}
            pieData= {generateAgePieData(candidates)}
            onClick={() => handleChartClick('Age')}
            expand={true}
        />
      );
    } else if (expandedChart === 'Experience') {
      return (
        <BarChart
            name={'Years of Experience'}
            data={yAxisValues}
            onClick={() => handleChartClick('Experience')}
            expand={true}
        />
      );
    }
  };

  const renderTabComponent = () => {
    if (svgActiveTab === 0) {
      return (
        <AnalyticFilter
            setStatus={setStatus}
            statusValue={statusValue}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            searchCSVObject={searchCSVObject}
            setSearchCSVObject={setSearchCSVObject}
            minMaxAge={minMaxAge}
            setMinMaxAge={setMinMaxAge}
            defaultSkills={skillsInput}
            defaultJobTitles={jobTitleInput}
        />
      );
    } else if (svgActiveTab === 1){
      return (
        <AnalyticThreshold
            setExperienceValue={setExperienceValue}
            experienceValue={experienceValue}
            expTitle='Total Years of Experience'
            skillTitle='Total number of Skills'
            skillValue={skillValue}
            setSkillValue={setSkillValue}
        />
      );
    } else if (svgActiveTab === 2){
      return (
        <Analytic3D
            scatter={scatter}
            setScatter={setScatter}
            view3D={view3D}
            setView3D={setView3D}
            markLines={markLines}
            setMarkLines={setMarkLines}
            nineBox={nineBox}
            setNineBox={setNineBox}
            searchObject={searchObject}
            setSearchObject={setSearchObject}
            searchCSVObject={searchCSVObject}
            setSearchCSVObject={setSearchCSVObject}
            setSimilarKeyword={setSimilarKeyword}
            similarKeyword={similarKeyword}
        />
      );
    } else if (svgActiveTab === 3){
      return (
        <AnalyticAction
            actionSelected={actionSelected}
            setActionSelected={setActionSelected}
            setSegmentedData={setSegmentedData}
            defaultSegment={defaultSegment}
        />
      );
    }
  };

  const categorizeExperience = (data: Applicant[]): number[] => {
    let buckets = [0, 0, 0, 0, 0, 0, 0];

    data.forEach(item => {
      const years = item.value[1];

      if (years >= 0 && years < 1) {
        buckets[0]++;
      } else if (years >= 1 && years <= 2) {
        buckets[1]++;
      } else if (years >= 3 && years <= 5) {
        buckets[2]++;
      } else if (years >= 6 && years <= 10) {
        buckets[3]++;
      } else if (years >= 11 && years <= 15) {
        buckets[4]++;
      } else if (years >= 16 && years <= 20) {
        buckets[5]++;
      } else if (years > 20) {
        buckets[6]++;
      }
    });

    return buckets;
  };

  const yAxisValues = categorizeExperience(candidates);

  const getOption = () => ({
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      name: 'Number of Skills',
      min: skillValue[0],
      max: skillValue[1],
      splitLine: {
        show: false,
      },
      nameLocation: 'middle',
      nameGap: 25,
    },
    yAxis: {
      name: 'Experience (Years)',
      min: experienceValue[0],
      max: experienceValue[1],
      splitLine: {
        show: false,
      },
      nameLocation: 'middle',
      nameGap: 30,
    },
    series: [{
      symbolSize: 25,
      itemStyle: {
        color: 'darkblue', // Set color of circular points to lime green
      },
      data: candidates.map(candidate => ({
        ...candidate,
        symbol: candidate.symbol,
      })),
      type: 'scatter',
      markLine: markLines && nineBox ? {
        silent: true,
        lineStyle: {
          color: 'grey',
        },
        data: [
          { xAxis: skillValue[0] + (1 / 3 * (skillValue[1] - skillValue[0])) },
          { xAxis: skillValue[0] + (2 / 3 * (skillValue[1] - skillValue[0])) },
          { yAxis: experienceValue[0] + (1 / 3 * (experienceValue[1] - experienceValue[0])) },
          { yAxis: experienceValue[0] + (2 / 3 * (experienceValue[1] - experienceValue[0])) },
        ],
        symbol: ['none'],
      } : {},
      markArea: nineBox ? {
        silent: false,
        itemStyle: {
          opacity: 0.1,
        },
        label: {
          position: 'inside',
          align: 'center',
          verticalAlign: 'middle',
          fontSize: 12,
          fontWeight: 'bold',
          color: 'darkgrey',
          emphasis: {
            position: 'inside',
            align: 'center',
            fontSize: 13,
            fontWeight: 'bold',
            color: 'grey',
          },
        },
        data: segmentedData,
      } : {},
    }],
  });

  const onChartClick = (param:any) => {
    if (param.data && param.data.name) {
      if (param.componentType === 'markArea') {
        if (svgActiveTab === 3){
          let isSelected = false;
          setSegmentedData((prevData) => {
            return prevData.map(segment => {
              if (segment[0].id === param.data.id) {
                isSelected = segment[0].itemStyle?.color === 'green';
                return segment.map(point => ({
                  ...point,
                  itemStyle: {
                    color: isSelected ? undefined : 'green',
                  },
                }));
              }
              return segment;
            });

          });
          if (!isSelected) {
            const filteredData = candidates.filter(item => {
              return item.value[0] >= param.data.coord[0][0] && item.value[0] <= param.data.coord[1][0]
                && item.value[1] >= param.data.coord[0][1] && item.value[1] <= param.data.coord[1][1];
            });

            const userIds = filteredData.map(user => user.userId);
            setActionSelected((prev) => {
              // Merge previous state with new ids and remove duplicates
              const mergedIds = Array.from(new Set([...prev, ...userIds]));
              return mergedIds;
            });
          } else {
            const filteredData = candidates.filter(item => {
              return item.value[0] >= param.data.coord[0][0] && item.value[0] <= param.data.coord[1][0]
                && item.value[1] >= param.data.coord[0][1] && item.value[1] <= param.data.coord[1][1];
            });

            const userIds = filteredData.map(user => user.userId);
            setActionSelected((prev) => {
              return prev.filter(id => !userIds.includes(id));
            });
          }
        } else {
          const filteredData = candidates.filter(item => {
            return item.value[0] >= param.data.coord[0][0] && item.value[0] <= param.data.coord[1][0]
                  && item.value[1] >= param.data.coord[0][1] && item.value[1] <= param.data.coord[1][1];
          });
          setXCoords([param.data.coord[0][0], param.data.coord[1][0]]);
          setYCoords([param.data.coord[0][1], param.data.coord[1][1]]);
          setSegmentedCandidates(filteredData);
          setSegmentTitle(param.data.name);
          setIsModalOpen(true);
        }
      }
      if (param.componentType === 'series' && param.data) {
        const nameExists = selectedCandidates.some(item => item.candidate && item.candidate.userId === param.data.userId);
        if (!nameExists) {
          setTabTitles([...tabTitles, param.data.name]);
          const selectedItem:Applicant | undefined = candidates.find(item => item.userId === param.data.userId);
          const newSelectedCandidates = [...selectedCandidates, { tabId: tabTitles.length, candidate: selectedItem }];
          setSelectedCandidates(newSelectedCandidates);
          setActiveTab(tabTitles.length);
        } else {
          toast.error('Tab for ' + param.data.name + ' already open', { position: 'bottom-center' });
        }
      }
    }
  };

  return (

    <div
        className='w-full min-h-screen p-4'
    >
      <div className='flex justify-between gap-4 ml-[8vw] mr-[1.5vw]'>
        <NumberInfo
            value={candidates.length}
            name={'Applicants'}
        />
        <NumberInfo
            value={20}
            name={'Days to Deadline'}
        />
        <PieChart
            name={'Gender'}
            pieData= {genderPieData(candidates)}
            onClick={() => handleChartClick('Gender')}
        />
        <PieChart
            name={'Age'}
            pieData= {generateAgePieData(candidates)}
            onClick={() => handleChartClick('Age')}
        />
        <BarChart
            name={'Years Experience'}
            data={yAxisValues}
            onClick={() => handleChartClick('Experience')}
        />
        <FilterWords
            filtersWords={status}
        />
        <ExportCsv
            searchCSVObject={searchCSVObject}
        />
      </div>
      <div className='flex justify-center'>
        <div className='item'>
          { renderExpandedChart() }
        </div>
      </div>
      <div className='flex justify-between'>
        { !view3D ? (
          <ReactECharts
              option={getOption()}
              style={{ height: '580px', width: svgActiveTab!==-1? '70%' : '100%' }}
              onEvents={{ click: onChartClick }}
          />
        ) : (
          <Bar3D
              scatter={scatter}
              svgActiveTab={svgActiveTab}
              data={candidates}
              setSelectedCandidates={setSelectedCandidates}
              selectedCandidates={selectedCandidates}
              setActiveTab={setActiveTab}
              setTabTitles={setTabTitles}
              tabTitles={tabTitles}
          />
        ) }
        { renderTabComponent() }
        <AnalysisTab
            activeTab = {svgActiveTab}
            setActiveTab = {setSvgActiveTab}
            setSearchObject = {setSearchObject}
            setMinMaxAge={setMinMaxAge}
            setExperienceValue={setExperienceValue}
            setSkillValue={setSkillValue}
        />
      </div>
      <Modal
          visible={isModalOpen}
          toggle={() => setIsModalOpen(false)}
          modalContent={loadModalContent()}
          customClass={styles.modalCustomClass}
          headerClass={styles.headerBackground}
          headerText={segmentTitle}
      />
      <div className='flex justify-end'>
        <Button
            onClick={() => handleApplyChange()}
            variant={'primary'}
            size={'medium'}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};
