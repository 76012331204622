import React from 'react';

type FilterWordsProps = {
    filtersWords: string[],
}

export const FilterWords = ({
  filtersWords,
}:FilterWordsProps) => {

  const keywords = ['New', 'Suitable', 'ShortList', 'Outstanding', 'Unsuccessful', 'Auto Assess'];

  return (
    <div className='p-1 border border-gray-300 rounded-md flex flex-wrap gap-1 bg-gray-100 max-w-[150px] text-xs'>
      { keywords.map((keyword, index) => (
        <span key={index} className={`px- py-0.5 rounded-md ${filtersWords.includes(keyword) ? 'bg-green-300' : 'bg-gray-200'}`}>
          { keyword }
        </span>
      )) }
    </div>
  );
};
