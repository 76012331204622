import ReactECharts from 'echarts-for-react';
import React from 'react';
import { PieItem } from '../../../../../utils/types/JobOpenings';

type PieChartProps = {
    name: string,
    pieData: PieItem[],
    onClick?: () => void,
    expand?: boolean,
}

export const PieChart = ({
  name,
  pieData,
  onClick,
  expand,
}:PieChartProps) => {

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // When the component mounts, initialize the chart
  const option = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'pie',
        radius: expand? '75%' : '100%',
        label: {
          show: true,
          position: expand? 'outside' : 'inside',
          formatter: '{b}, {c}',
          fontSize: expand? 12 : 6,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        data: pieData,
      },
    ],
  };

  return (
    <div onClick={handleClick}>
      <ReactECharts
          option={option}
          style={{ height: expand? '400px':'85px', width: expand? '600px' : '85px' }}
      />
      <p className='text-center font-bold text-green-500 text-xs'>{ name }</p>
    </div>
  );
};
