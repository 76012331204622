import React from 'react';

type NumberInfoProps = {
    value: number,
    name: string,
}

export const NumberInfo = ({
  value,
  name,
}:NumberInfoProps) => {

  return (
    <div className='flex flex-col items-center justify-center'>
      <p className='text-7xl p-1 text-center font-bold text-green-500'>{ value }</p>
      <p className='text-center font-bold text-green-500 text-xs'>{ name }</p>
    </div>
  );
};
