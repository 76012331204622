import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnalyticAllSvg from '../../../../assets/images/graphics/analytic-all.svg';
import AnalyticAssessmentSvg from '../../../../assets/images/graphics/analytic-assessment.svg';
import AnalyticOutstandingSvg from '../../../../assets/images/graphics/analytic-outstanding.svg';
import AnalyticShortListSvg from '../../../../assets/images/graphics/analytic-shortList.svg';
import AnalyticSuitableSvg from '../../../../assets/images/graphics/analytic-suitable.svg';
import AnalyticUnsuccessfulISvg from '../../../../assets/images/graphics/analytic-unsuccessfulI.svg';
import { getJobDescription } from '../../../../utils/api_service/JobOpenings';
import { JobOpeningsContext } from '../../../../utils/contexts/JobOpening';
import { TabData } from '../../../../utils/types/JobOpenings';
import { Tabs } from '../../../assessment/view/tabs/tabs';
import ButtonColumn, { SingleButton } from '../ui/ButtonColumn';
import { CandidateProfile } from './CandidateProfile';
import { TwoDChart } from './TwoDChart';

export const AllApplicantAnalysis = () => {
  const [tabTitles, setTabTitles] = useState<string[]>(['All Applicants']);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedCandidates, setSelectedCandidates] = useState<TabData[]>([]);
  const { jobOpeningElements } = useContext(JobOpeningsContext);
  const [currentTab, setCurrentTab] = useState<string>('All Applicants');
  const [status, setStatus] = useState<string[]>(['New', 'Suitable', 'Outstanding', 'ShortList', 'Unsuccessful', 'Auto Assess']);
  const [statusValue, setStatusValue] = useState<string[]>([]);
  const [similarKeyword, setSimilarKeyword] = useState<string>('');
  const [skillsInput, setSkillsInput] = useState<string>('');
  const [jobTitleInput, setJobTitleInput] = useState<string>('');
  const [defaultSkills, setDefaultSkills] = useState<string[]>([]);
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const elementsExceptFirst = tabTitles.slice(1);
    const filteredSelection = selectedCandidates.filter(item => item.candidate && elementsExceptFirst.includes(item.candidate.name));
    const updatedSelection = filteredSelection.map((item, index) => ({
      ...item,
      tabId: index + 1,
    }));
    setSelectedCandidates(updatedSelection);
  }, [activeTab]);

  useEffect(() => {
    const fetchJobDescriptionDetails = async () => {
      const response = await getJobDescription(id);
      const responseData = await response.json();
      const mandatorySkills = responseData.mandatorySkills;
      const mandatorySkillsString = mandatorySkills.join(':');
      const jobTitle = responseData.jobTitle.jobTitle;
      setDefaultSkills(mandatorySkills);
      setJobTitleInput(jobTitle);
      setSkillsInput(mandatorySkillsString);
    };
    fetchJobDescriptionDetails();
  }, []);

  useEffect(() => {
    switch (currentTab) {
      case 'All Applicants':
        setStatusValue([]);
        setStatus(['New', 'Suitable', 'Outstanding', 'ShortList', 'Unsuccessful', 'Auto Assess']);
        break;
      case 'Suitable Applicants':
        setStatus(['Suitable']);
        setStatusValue(['Suitable']);
        break;
      case 'Outstanding Applicants':
        setStatus(['Outstanding']);
        setStatusValue(['Outstanding']);
        break;
      case 'Assessment Applicants':
        setStatus(['Auto-Assessment']);
        setStatusValue(['Auto-Assessment']);
        break;
      case 'Unsuccessful Applicants':
        setStatus(['Unsuccessful']);
        setStatusValue(['Unsuccessful-Closed']);
        break;
      case 'ShortList Applicants':
        setStatus(['ShortList']);
        setStatusValue(['Shortlisted']);
        break;
      default:
        break;
    }
  }, [currentTab]);

  const renderTab = () => {
    if (activeTab > tabTitles.length-1) {
      setActiveTab(activeTab-1);
    }
    if (activeTab === 0) {
      return (
        <TwoDChart
            tabTitles={tabTitles}
            setTabTitles={setTabTitles}
            setActiveTab={setActiveTab}
            selectedCandidates={selectedCandidates}
            setSelectedCandidates={setSelectedCandidates}
            setStatus={setStatus}
            status={status}
            statusValue={statusValue}
            setSimilarKeyword={setSimilarKeyword}
            similarKeyword={similarKeyword}
            defaultSkills={defaultSkills}
            skillsInput={skillsInput}
            jobTitleInput={jobTitleInput}
        />
      );
    }
    else {
      const tabCandidate = selectedCandidates.find(item => item.tabId === activeTab);
      const candidateValues = tabCandidate?.candidate;
      if (candidateValues) {
        return(
          <CandidateProfile
              selectedCandidate={candidateValues}
          />
        );
      }
    }
  };

  const buttonsList: SingleButton[] = useMemo(() => ([
    {
      label: 'All Applicants',
      disabled: false,
      svg: AnalyticAllSvg,
    },
    {
      label: 'Suitable Applicants',
      disabled: false,
      svg: AnalyticSuitableSvg,
    },
    {
      label: 'Outstanding Applicants',
      disabled: false,
      svg: AnalyticOutstandingSvg,
    },
    {
      label: 'Assessment Applicants',
      disabled: false,
      svg: AnalyticAssessmentSvg,
    },
    {
      label: 'Unsuccessful Applicants',
      disabled: false,
      svg: AnalyticUnsuccessfulISvg,
    },
    {
      label: 'ShortList Applicants',
      disabled: false,
      svg: AnalyticShortListSvg,
    },
  ]), [jobOpeningElements]);

  const handleStatusChange = (activeLabel: string) => {
    setCurrentTab(activeLabel);
  };

  return (
    <div className='flex'>
      <ButtonColumn buttons={buttonsList} onStatusChange={handleStatusChange} currentTab={currentTab}/>
      <div
          className='w-full min-h-[80vh] bg-primaryWhite100 shadow-[0_16px_30px_rgba(55,92,170,0.15)] rounded-lg box-border'
      >
        <Tabs
            tabTitles={tabTitles}
            setTabTitles={setTabTitles}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            addClose={true}
        />
        { renderTab() }
      </div>
    </div>
  );
};
